var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_vm.userRole != 'Client' && [0].includes(_vm.wasiat.status) ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center",
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(_vm._s(_vm.$t("payment")))]), _vm.payment_received_at && _vm.wasiat.payment_options_id == null && _vm.wasiat.promo_code ? _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "alert alert-success"
  }, [_vm._v(_vm._s(_vm.$t("payment-waived")))])]) : _vm._e(), [0].includes(_vm.wasiat.status) && _vm.payment_received_at == null ? _c('div', {
    staticClass: "row mb-2 mt-4"
  }, [!_vm.pengesahan ? _c('div', {
    staticClass: "col-md-12 form-group text-center"
  }, [_c('label', {
    attrs: {
      "for": "organization_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("methods-of-payment")))]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.payment_options_id,
      expression: "payment_options_id"
    }],
    staticClass: "form-control",
    attrs: {
      "id": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.payment_options_id = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, _vm._l(_vm.dropdownItems, function (dropdownItem, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": dropdownItem.id
      }
    }, [_vm._v(" " + _vm._s(dropdownItem.value) + " ")]);
  }), 0)]) : _vm._e()]) : _vm._e(), _c('hr'), _vm.payment_options_id == '1' && _vm.userRole != 'Client' && !_vm.pengesahan ? _c('div', {
    staticClass: "row mb-2 mt-4"
  }, [_vm.payment_received_at == null ? _c('div', {
    staticClass: "col-md-12 form-group text-center"
  }, [_c('label', {
    attrs: {
      "for": "1"
    }
  }, [_c('u', [_vm._v(_vm._s(_vm.$t("payment-gateway")))])]), [0].includes(_vm.wasiat.status) ? _c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(" " + _vm._s(_vm.$t("pls-generate-link-share-to-testator")) + " "), _c('div', {
    staticClass: "text-center mt-4"
  }, [_c('b-button', {
    staticClass: "btn-sm btn-primary",
    on: {
      "click": _vm.generatePaymentGateWayURL
    }
  }, [_vm._v(_vm._s(_vm.$t("generate-link")))])], 1), _c('div', {
    staticClass: "input-group mt-3"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.Url_Generated,
      expression: "Url_Generated",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "readonly": "",
      "placeholder": "Pautan untuk dikongsi",
      "aria-label": "Text input with checkbox",
      "id": "url-code"
    },
    domProps: {
      "value": _vm.Url_Generated
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.Url_Generated = $event.target.value.trim();
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _c('div', {
    staticClass: "input-group-append"
  }, [_c('b-button', {
    staticClass: "btn-sm btn-secondary",
    on: {
      "click": _vm.copyPaymentGatewayCode
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("copy")) + " ")])], 1)])]) : _vm._e()]) : _vm._e(), _vm.payment_received_at ? _c('div', {
    staticClass: "col-md-12 form-group text-center"
  }, [_c('label', {
    attrs: {
      "for": "1"
    }
  }, [_c('u', [_vm._v(_vm._s(_vm.$t("payment-gateway")))])]), _c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(_vm._s(_vm.$t("payment-of-bequest-worth")) + " RM" + _vm._s(_vm.wasiat.price) + " " + _vm._s(_vm.$t("has-been-successfully-made")))])]) : _vm._e(), _vm.payment_received_at ? _c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "target": "_blank",
      "to": {
        name: 'wasiat.receipt',
        params: {
          wasiat_id: this.wasiatId
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("print-receipt")))])], 1) : _vm._e()]) : _vm._e(), _vm.payment_options_id == '2' && _vm.wasiat.status == 0 ? _c('div', {
    staticClass: "row mb-2 mt-4"
  }, [_c('div', {
    staticClass: "col-md-12 form-group text-center"
  }, [_c('label', {
    attrs: {
      "for": "2 "
    }
  }, [_c('u', [_vm._v(_vm._s(_vm.$t("offline-payment")) + " 1 (" + _vm._s(_vm.$t("testator-payment-to-awaris")) + ")")])]), _vm.payment_received_at == null ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t("pls-upload-proof-payment")) + " "), _c('strong', [_vm._v("RM" + _vm._s(_vm.wasiat.price))]), _vm._v(". "), _c('br'), _vm._v(" " + _vm._s(_vm.$t("after-uploading-press-save-button")) + " ")]), _c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "upload-photo-2"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("upload-proof-payment")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "card rounded shadow bg-dark"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center bd-highlight",
    staticStyle: {
      "height": "150px"
    }
  }, [_vm.receipt.imageUrl ? _c('label', {
    staticStyle: {
      "border-radius": "5px",
      "margin-bottom": "0",
      "background-position": "center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
      "height": "100%",
      "width": "100%"
    },
    style: 'background-image: url(' + _vm.receipt.imageUrlDisplay + ');',
    attrs: {
      "for": "upload-photo-2"
    }
  }) : _vm._e(), !_vm.receipt.imageUrl ? _c('label', {
    staticClass: "upload-photo-dark text-center",
    attrs: {
      "for": "upload-photo-2"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("select-copy")))])]) : _vm._e(), _c('input', {
    staticClass: "upload-photo-input",
    attrs: {
      "type": "file",
      "name": "photo",
      "id": "upload-photo-2",
      "accept": "image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
    },
    on: {
      "change": function ($event) {
        return _vm.filesChange($event.target.files, 'receipt');
      }
    }
  })])])])])]), _vm.receipt.imageUrl ? _c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('b-button', {
    staticClass: "btn btn-primary m-1",
    on: {
      "click": _vm.receiptSubmit
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("submit")) + " ")]), _c('b-button', {
    staticClass: "btn btn-primary m-1",
    on: {
      "click": _vm.openToView
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("view")) + " ")])], 1)]) : _vm._e()]) : _vm._e(), _vm.payment_received_at ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(_vm._s(_vm.$t("payment-of-bequest-worth")) + " RM" + _vm._s(_vm.wasiat.price) + " " + _vm._s(_vm.$t("has-been-successfully-made")))])]), _vm.receipt.imageUrl ? _c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "form-group",
    staticStyle: {
      "width": "40%"
    }
  }, [_c('label', {
    attrs: {
      "for": "upload-photo-2"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("proof-of-payment")) + " ")]), _c('div', {
    staticClass: "card rounded shadow bg-dark"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center bd-highlight",
    staticStyle: {
      "height": "150px"
    }
  }, [_vm.receipt.imageUrl ? _c('label', {
    staticStyle: {
      "border-radius": "5px",
      "margin-bottom": "0",
      "background-position": "center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
      "height": "100%",
      "width": "100%"
    },
    style: 'background-image: url(' + _vm.receipt.imageUrlDisplay + ');',
    attrs: {
      "for": "upload-photo-2"
    }
  }) : _vm._e()])]), _c('b-button', {
    staticClass: "m-2 btn btn-sm btn-primary",
    on: {
      "click": _vm.showReceipt
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("view")) + " ")])], 1)])]) : _vm._e(), _c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "target": "_blank",
      "to": {
        name: 'wasiat.receipt',
        params: {
          wasiat_id: this.wasiatId
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("print-receipt")))])], 1)]) : _vm._e()])]) : _vm._e(), [0].includes(_vm.wasiat.status) && _vm.payment_options_id == '3' ? _c('div', {
    staticClass: "row mb-2 mt-4"
  }, [_c('div', {
    staticClass: "col-md-12 form-group text-center"
  }, [_c('label', {
    attrs: {
      "for": "3"
    }
  }, [_c('u', [_vm._v(_vm._s(_vm.$t("offline-payment")) + " 2 (" + _vm._s(_vm.$t("testator-payment-to-agencies-banks-etc")) + ")")])]), [0].includes(_vm.wasiat.status) && _vm.payment_received_at == null ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("press-button-below-after-receiving-payment")) + ".")])])]) : _vm._e(), [0].includes(_vm.wasiat.status) && _vm.payment_received_at == null ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('div', {
    staticClass: "form-check"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.is_cash_payment,
      expression: "form.is_cash_payment"
    }],
    staticClass: "form-check-input",
    attrs: {
      "type": "checkbox",
      "id": "is_cash_payment",
      "value": "1"
    },
    domProps: {
      "checked": Array.isArray(_vm.form.is_cash_payment) ? _vm._i(_vm.form.is_cash_payment, "1") > -1 : _vm.form.is_cash_payment
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.form.is_cash_payment,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "1",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "is_cash_payment", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "is_cash_payment", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "is_cash_payment", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "label-agree-term",
    attrs: {
      "for": "is_cash_payment"
    }
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.$t("i-received-the-money")) + " RM"), _c('span', [_vm._v(_vm._s(_vm.wasiat.price))])])])])])]) : _vm._e(), [0].includes(_vm.wasiat.status) && _vm.payment_received_at == null ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('b-button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.updateCashPayment
    }
  }, [_vm._v(_vm._s(_vm.$t("accept-payment2")) + " "), _vm.acceptPay ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()])], 1)]) : _vm._e(), [0].includes(_vm.wasiat.status) && _vm.payment_received_at ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(" " + _vm._s(_vm.$t("payment-has-been-received-amounting-to")) + " "), _c('strong', [_vm._v("RM" + _vm._s(_vm.wasiat.price))])]), _c('div', {
    staticClass: "row"
  }, [_vm.userRole == 'Staff' && 'Agent' ? _c('div', {
    staticClass: "col-md-12 d-flex justify-content-around"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.showAlert
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("send-payment-notification")) + " ")])]) : _vm._e()])])]), _c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "target": "_blank",
      "to": {
        name: 'wasiat.receipt',
        params: {
          wasiat_id: this.wasiatId
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("print-receipt")))])], 1)]) : _vm._e()])]) : _vm._e(), [0].includes(_vm.wasiat.status) && _vm.payment_options_id == '4' ? _c('div', {
    staticClass: "row mb-2 mt-4"
  }, [_c('div', {
    staticClass: "col-md-12 form-group text-center"
  }, [_c('label', {
    attrs: {
      "for": "3"
    }
  }, [_c('u', [_vm._v(_vm._s(_vm.$t("financing-payments")))])]), [0].includes(_vm.wasiat.status) && _vm.payment_received_at == null ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("press-button-below-submit-financing")) + ".")])])]) : _vm._e(), [0].includes(_vm.wasiat.status) && _vm.payment_received_at == null ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('b-button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.updateLoanPayment
    }
  }, [_vm._v(_vm._s(_vm.$t("apply")) + " "), _vm.acceptPay ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()])], 1)]) : _vm._e(), [0].includes(_vm.wasiat.status) && _vm.payment_received_at ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(" " + _vm._s(_vm.$t("payment-will-be-financed-by")) + " "), _c('strong', [_vm._v("RM" + _vm._s(_vm.wasiat.price))])])]), _c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "target": "_blank",
      "to": {
        name: 'wasiat.receipt',
        params: {
          wasiat_id: this.wasiatId
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("print-receipt")))])], 1)]) : _vm._e()])]) : _vm._e()])])])]) : _vm._e(), ['Client'].includes(_vm.userRole) ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center",
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("payment")) + " ")]), _vm.payment_received_at && _vm.wasiat.payment_options_id == 5 && _vm.wasiat.promo_code ? _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "alert alert-success"
  }, [_vm._v(_vm._s(_vm.$t("payment-waived")))])]) : _vm._e(), [0].includes(_vm.wasiat.status) ? _c('div', {
    staticClass: "row mb-2 mt-2"
  }, [_vm.payment_received_at == null ? _c('div', {
    staticClass: "col-md-12 form-group text-center"
  }, [_c('label', {
    attrs: {
      "for": "organization_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("methods-of-payment")))]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.payment_options_id,
      expression: "payment_options_id"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "payment_options"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.payment_options_id = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "-"
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("pls-select")) + " --")]), _c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v(_vm._s(_vm.$t("payment-gateway")))])])]) : _vm._e()]) : _vm._e(), _c('hr'), [0].includes(_vm.wasiat.status) && _vm.payment_options_id == 1 ? _c('div', {
    staticClass: "row mb-2 mt-4"
  }, [_vm.payment_received_at == null ? _c('div', {
    staticClass: "col-md-12 form-group text-center"
  }, [_c('label', {
    attrs: {
      "for": "1"
    }
  }, [_c('u', [_vm._v(_vm._s(_vm.$t("payment-gateway")))])]), _c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(" " + _vm._s(_vm.$t("pls-press-button-below-to-make-payment")) + ". "), _c('div', {
    staticClass: "col-md-12 text-center mt-4"
  }, [_c('b-button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.modalPG
    }
  }, [_vm._v(_vm._s(_vm.$t("online-payment")))])], 1)])]) : _vm._e()]) : _vm._e(), [0].includes(_vm.wasiat.status) && _vm.payment_options_id == 1 && _vm.paymentStatus ? _c('div', {
    staticClass: "row mb-2 mt-4"
  }, [_vm.paymentStatus == 1 ? _c('div', {
    staticClass: "col-md-12 form-group text-center"
  }, [_c('label', {
    attrs: {
      "for": "1"
    }
  }, [_c('u', [_vm._v(_vm._s(_vm.$t("payment-gateway")))])]), _c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(_vm._s(_vm.$t("payment-of-bequest-worth")) + " RM" + _vm._s(_vm.wasiat.price) + " " + _vm._s(_vm.$t("has-been-successfully-made")))])]) : _vm._e()]) : _vm._e(), [0].includes(_vm.wasiat.status) && _vm.payment_options_id == 1 && _vm.paymentStatus ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 form-group text-center"
  }, [_vm.paymentStatus != 1 ? _c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(" " + _vm._s(_vm.$t("sorry")) + ". "), _c('br'), _vm._v(_vm._s(_vm.$t("your-will-payment-has-failed")) + ". "), _c('br'), _vm._v(" " + _vm._s(_vm.$t("pls-try-again")) + ". ")]) : _vm._e()])]) : _vm._e(), [2].includes(_vm.wasiat.status) && _vm.payment_options_id == 1 ? _c('div', {
    staticClass: "row mb-2 mt-4"
  }, [_c('div', {
    staticClass: "col-md-12 form-group text-center"
  }, [_c('label', {
    attrs: {
      "for": "1"
    }
  }, [_c('u', [_vm._v(_vm._s(_vm.$t("payment-gateway")))])]), _vm.payment_received_at ? _c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(_vm._s(_vm.$t("payment-of-bequest-worth")) + " RM" + _vm._s(_vm.wasiat.price) + " " + _vm._s(_vm.$t("has-been-successfully-made")))]) : _vm._e(), _c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "target": "_blank",
      "to": {
        name: 'wasiat.receipt',
        params: {
          wasiat_id: this.wasiatId
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("print-receipt")))])], 1)]), !_vm.payment_received_at ? _c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(_vm._s(_vm.$t("payment-of-will-failed")) + ".")]) : _vm._e()])]) : _vm._e(), [2].includes(_vm.wasiat.status) && _vm.payment_options_id == 2 ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 form-group text-center"
  }, [_c('label', {
    attrs: {
      "for": "2"
    }
  }, [_c('u', [_vm._v(_vm._s(_vm.$t("offline-payment")) + " 1 (" + _vm._s(_vm.$t("testator-payment-to-awaris")) + ")")])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "form-group",
    staticStyle: {
      "width": "40%"
    }
  }, [_c('label', {
    attrs: {
      "for": "upload-photo-2"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("proof-of-payment")) + " ")]), _c('div', {
    staticClass: "card rounded shadow bg-dark"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center bd-highlight",
    staticStyle: {
      "height": "150px"
    }
  }, [_vm.receipt.imageUrl ? _c('label', {
    staticStyle: {
      "border-radius": "5px",
      "margin-bottom": "0",
      "background-position": "center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
      "height": "100%",
      "width": "100%"
    },
    style: 'background-image: url(' + _vm.receipt.imageUrlDisplay + ');',
    attrs: {
      "for": "upload-photo-2"
    }
  }) : _vm._e()])]), _c('b-button', {
    staticClass: "mt-2 btn-sm btn-primary",
    on: {
      "click": _vm.showReceipt
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("view")) + " ")])], 1)]), _c('div', {
    staticClass: "row justify-content-center"
  }, [_vm.payment_received_at ? _c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "target": "_blank",
      "to": {
        name: 'wasiat.receipt',
        params: {
          wasiat_id: this.wasiatId
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("print-receipt")))])], 1) : _vm._e()])])])])]) : _vm._e(), [2].includes(_vm.wasiat.status) && _vm.payment_options_id == 3 ? _c('div', {
    staticClass: "row mb-2 mt-4"
  }, [_c('div', {
    staticClass: "col-md-12 form-group text-center"
  }, [_c('label', {
    attrs: {
      "for": "3"
    }
  }, [_c('u', [_vm._v(_vm._s(_vm.$t("offline-payment")) + " 2 (" + _vm._s(_vm.$t("testator-payment-to-agencies-banks-etc")) + ")")])]), _c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(_vm._s(_vm.$t("payment-of-bequest-worth")) + " RM" + _vm._s(_vm.wasiat.price) + " " + _vm._s(_vm.$t("has-been-successfully-made")))]), _c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "target": "_blank",
      "to": {
        name: 'wasiat.receipt',
        params: {
          wasiat_id: this.wasiatId
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("print-receipt")))])], 1)])])]) : _vm._e(), [2].includes(_vm.wasiat.status) && _vm.payment_options_id == 4 ? _c('div', {
    staticClass: "row mb-2 mt-4"
  }, [_c('div', {
    staticClass: "col-md-12 form-group text-center"
  }, [_c('label', {
    attrs: {
      "for": "3"
    }
  }, [_c('u', [_vm._v(_vm._s(_vm.$t("financing-payments")))])]), _c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(_vm._s(_vm.$t("payment-of-bequest-worth")) + " RM" + _vm._s(_vm.wasiat.price) + " " + _vm._s(_vm.$t("will-be-included-in-your-financing")) + ".")]), _c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "target": "_blank",
      "to": {
        name: 'wasiat.receipt',
        params: {
          wasiat_id: this.wasiatId
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("print-receipt")))])], 1)])])]) : _vm._e()])])])]) : _vm._e(), [1].includes(_vm.wasiat.status) && _vm.userRole == 'Staff' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center",
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("payment")) + " ")]), _c('hr'), _c('div', {
    staticClass: "row mb-2 mt-4"
  }, [_c('div', {
    staticClass: "col-md-12 form-group text-center"
  }, [_c('label', {
    attrs: {
      "for": "payment_date"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("payment-date")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "input-group mt-3"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.form.payment_reconciled_at,
      expression: "form.payment_reconciled_at",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "date",
      "id": "payment_reconciled_at"
    },
    domProps: {
      "value": _vm.form.payment_reconciled_at
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "payment_reconciled_at", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })])])]), _c('div', {
    staticClass: "row mb-2 mt-4"
  }, [_c('div', {
    staticClass: "col-md-12 form-group text-center"
  }, [_c('label', {
    attrs: {
      "for": "payment_date"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("account-type")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "input-group mt-3"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.bank_account_id,
      expression: "bank_account_id"
    }],
    staticClass: "form-control",
    attrs: {
      "name": "bank_account_id",
      "id": "bank_account_id"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.bank_account_id = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "disabled": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("account-type")))]), _vm._l(_vm.bank_account, function (bank_account) {
    return _c('option', {
      domProps: {
        "value": bank_account
      }
    }, [_vm._v(" " + _vm._s(bank_account.bank_name) + " - " + _vm._s(bank_account.account_number) + " ")]);
  })], 2)])])]), _c('div', {
    staticClass: "row mb-2 mt-4"
  }, [_vm.payment_options_id == 1 && _vm.payment_received_at ? _c('div', {
    staticClass: "col-md-12 form-group text-center"
  }, [_c('label', {
    attrs: {
      "for": "1"
    }
  }, [_c('u', [_vm._v(_vm._s(_vm.$t("payment-gateway")))])]), _c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(_vm._s(_vm.$t("payment-of-bequest-worth")) + " RM" + _vm._s(_vm.wasiat.price) + " " + _vm._s(_vm.$t("has-been-successfully-made")))]), _c('div', {
    staticClass: "row justify-content-center"
  }, [_vm.payment_received_at ? _c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "target": "_blank",
      "to": {
        name: 'wasiat.receipt',
        params: {
          wasiat_id: this.wasiatId
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("print-receipt")))])], 1) : _vm._e()])]) : _vm._e(), _vm.payment_options_id == 1 && !_vm.payment_received_at ? _c('div', {
    staticClass: "col-md-12 form-group text-center"
  }, [_c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(_vm._s(_vm.$t("payment-of-will-failed")) + ".")])]) : _vm._e(), _vm.payment_options_id == 2 ? _c('div', {
    staticClass: "col-md-12 form-group text-center"
  }, [_c('label', {
    attrs: {
      "for": "2"
    }
  }, [_c('u', [_vm._v(_vm._s(_vm.$t("offline-payment")) + " 1 (" + _vm._s(_vm.$t("testator-payment-to-awaris")) + ")")])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "form-group",
    staticStyle: {
      "width": "40%"
    }
  }, [_c('label', {
    attrs: {
      "for": "upload-photo-2"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("proof-of-payment")) + " ")]), _c('div', {
    staticClass: "card rounded shadow bg-dark"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center bd-highlight",
    staticStyle: {
      "height": "150px"
    }
  }, [_vm.receipt.imageUrl ? _c('label', {
    staticStyle: {
      "border-radius": "5px",
      "margin-bottom": "0",
      "background-position": "center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
      "height": "100%",
      "width": "100%"
    },
    style: 'background-image: url(' + _vm.receipt.imageUrlDisplay + ');',
    attrs: {
      "for": "upload-photo-2"
    }
  }) : _vm._e(), !_vm.receipt.imageUrl ? _c('label', {
    staticClass: "upload-photo-dark text-center",
    attrs: {
      "for": "upload-photo-2"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("select-copy")))])]) : _vm._e(), _c('input', {
    staticClass: "upload-photo-input",
    attrs: {
      "type": "file",
      "name": "photo",
      "id": "upload-photo-2",
      "accept": "image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
    },
    on: {
      "change": function ($event) {
        return _vm.filesChange($event.target.files, 'receipt');
      }
    }
  })])]), !_vm.payment_received_at ? _c('b-button', {
    staticClass: "m-2 btn btn-sm btn-primary",
    on: {
      "click": _vm.receiptSubmit
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("submit")) + " ")]) : _vm._e(), _c('b-button', {
    staticClass: "m-2 btn btn-sm btn-primary",
    on: {
      "click": _vm.showReceipt
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("view")) + " ")])], 1)]), _vm.userRole == 'Staff' && _vm.pengesahan ? _c('div', {
    staticClass: "row mt-2"
  }, [_vm.payment_received_at == null ? _c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('b-button', {
    staticClass: "btn btn-success mr-1",
    on: {
      "click": _vm.approveReceipt
    }
  }, [_vm._v(_vm._s(_vm.$t("confirm")))])], 1) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_vm.payment_received_at ? _c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "target": "_blank",
      "to": {
        name: 'wasiat.receipt',
        params: {
          wasiat_id: this.wasiatId
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("print-receipt")))])], 1) : _vm._e()])])])]) : _vm._e(), _vm.payment_options_id == 3 ? _c('div', {
    staticClass: "col-md-12 form-group text-center"
  }, [_c('label', {
    attrs: {
      "for": "3"
    }
  }, [_c('u', [_vm._v(_vm._s(_vm.$t("offline-payment")) + " 2 (" + _vm._s(_vm.$t("testator-payment-to-agencies-banks-etc")) + ")")])]), _vm.payment_received_at ? _c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(_vm._s(_vm.$t("payment-of-bequest-worth")) + " RM" + _vm._s(_vm.wasiat.price) + " " + _vm._s(_vm.$t("has-been-successfully-made")))]) : _vm._e(), _c('div', {
    staticClass: "row justify-content-center"
  }, [_vm.payment_received_at ? _c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "target": "_blank",
      "to": {
        name: 'wasiat.receipt',
        params: {
          wasiat_id: this.wasiatId
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("print-receipt")))])], 1) : _vm._e()])]) : _vm._e(), _vm.payment_options_id == 4 ? _c('div', {
    staticClass: "col-md-12 form-group text-center"
  }, [_c('label', {
    attrs: {
      "for": "3"
    }
  }, [_c('u', [_vm._v(_vm._s(_vm.$t("financing-payments")))])]), _vm.payment_received_at ? _c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(_vm._s(_vm.$t("payment-will-be-financed-by")) + " RM" + _vm._s(_vm.wasiat.price))]) : _vm._e(), _c('div', {
    staticClass: "row justify-content-center"
  }, [_vm.payment_received_at ? _c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "target": "_blank",
      "to": {
        name: 'wasiat.receipt',
        params: {
          wasiat_id: this.wasiatId
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("print-receipt")))])], 1) : _vm._e()])]) : _vm._e()])])])])]) : _vm._e(), [2].includes(_vm.wasiat.status) && _vm.userRole == 'Staff' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center",
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("payment")) + " ")]), _c('hr'), _c('div', {
    staticClass: "row mb-2 mt-4"
  }, [_c('div', {
    staticClass: "col-md-12 form-group text-center"
  }, [_c('label', {
    attrs: {
      "for": "payment_date"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("payment-date")) + " ")]), _c('div', {
    staticClass: "mt-3 text-center"
  }, [_vm.wasiat ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm._f("toHumanDate")(_vm.wasiat.payment_reconciled_at)) + " ")]) : _vm._e()])])]), _c('div', {
    staticClass: "row mb-2 mt-4"
  }, [_c('div', {
    staticClass: "col-md-12 form-group text-center"
  }, [_c('label', {
    attrs: {
      "for": "payment_date"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("account-type")) + " ")]), _c('div', {
    staticClass: "input-group mt-3"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedBankAccounts,
      expression: "selectedBankAccounts"
    }],
    staticClass: "form-control",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.selectedBankAccounts = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "disabled": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("account-type")))])])])])]), _c('div', {
    staticClass: "row mb-2 mt-4"
  }, [_vm.payment_options_id == 1 && _vm.payment_received_at ? _c('div', {
    staticClass: "col-md-12 form-group text-center"
  }, [_c('label', {
    attrs: {
      "for": "1"
    }
  }, [_c('u', [_vm._v(_vm._s(_vm.$t("payment-gateway")))])]), _c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(_vm._s(_vm.$t("payment-of-bequest-worth")) + " RM" + _vm._s(_vm.wasiat.price) + " " + _vm._s(_vm.$t("has-been-successfully-made")))]), _c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "target": "_blank",
      "to": {
        name: 'wasiat.receipt',
        params: {
          wasiat_id: this.wasiatId
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("print-receipt")))])], 1)])]) : _vm._e(), _vm.payment_options_id == 2 ? _c('div', {
    staticClass: "col-md-12 form-group text-center"
  }, [_c('label', {
    attrs: {
      "for": "2"
    }
  }, [_c('u', [_vm._v(_vm._s(_vm.$t("offline-payment")) + " 1 (" + _vm._s(_vm.$t("testator-payment-to-awaris")) + ")")])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "form-group",
    staticStyle: {
      "width": "40%"
    }
  }, [_c('label', {
    attrs: {
      "for": "upload-photo-2"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("proof-of-payment")) + " ")]), _c('div', {
    staticClass: "card rounded shadow bg-dark"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center bd-highlight",
    staticStyle: {
      "height": "150px"
    }
  }, [_vm.receipt.imageUrl ? _c('label', {
    staticStyle: {
      "border-radius": "5px",
      "margin-bottom": "0",
      "background-position": "center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
      "height": "100%",
      "width": "100%"
    },
    style: 'background-image: url(' + _vm.receipt.imageUrlDisplay + ');',
    attrs: {
      "for": "upload-photo-2"
    }
  }) : _vm._e()])]), _c('b-button', {
    staticClass: "mt-2 btn-sm btn-primary",
    on: {
      "click": _vm.showReceipt
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("view")) + " ")])], 1)]), _c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_vm.payment_received_at ? _c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "target": "_blank",
      "to": {
        name: 'wasiat.receipt',
        params: {
          wasiat_id: this.wasiatId
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("print-receipt")))])], 1) : _vm._e()])])])]) : _vm._e(), _vm.payment_options_id == 3 && _vm.payment_received_at ? _c('div', {
    staticClass: "col-md-12 form-group text-center"
  }, [_c('label', {
    attrs: {
      "for": "3"
    }
  }, [_c('u', [_vm._v(_vm._s(_vm.$t("offline-payment")) + " 2 (" + _vm._s(_vm.$t("testator-payment-to-agencies-banks-etc")) + ")")])]), _c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(_vm._s(_vm.$t("payment-of-bequest-worth")) + " RM" + _vm._s(_vm.wasiat.price) + " " + _vm._s(_vm.$t("has-been-successfully-made")))]), _c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "target": "_blank",
      "to": {
        name: 'wasiat.receipt',
        params: {
          wasiat_id: this.wasiatId
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("print-receipt")))])], 1)])]) : _vm._e(), _vm.payment_options_id == 4 && _vm.payment_received_at ? _c('div', {
    staticClass: "col-md-12 form-group text-center"
  }, [_c('label', {
    attrs: {
      "for": "3"
    }
  }, [_c('u', [_vm._v(_vm._s(_vm.$t("financing-payments")))])]), _c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(_vm._s(_vm.$t("payment-of-bequest-worth")) + " RM" + _vm._s(_vm.wasiat.price) + " " + _vm._s(_vm.$t("will-be-incl-in-financing")))]), _c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "target": "_blank",
      "to": {
        name: 'wasiat.receipt',
        params: {
          wasiat_id: this.wasiatId
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("print-receipt")))])], 1)])]) : _vm._e()])])])])]) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("next")) + " ")])])])]), _c('b-modal', {
    ref: "modalPG",
    attrs: {
      "title": "Jumlah Pembayaran",
      "no-enforce-focus": true,
      "hide-footer": ""
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('label', {
    staticClass: "label-agree-term"
  }, [_c('strong', [_vm._v(_vm._s(_vm.$t("agree-with-payment")))])])]), _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('label', [_vm.wasiat.promo_code ? _c('strong', [_vm._v("RM " + _vm._s(_vm.wasiat.price - _vm.wasiat.promo_code.amount_discount) + " (" + _vm._s(_vm.$t("price-after-promo-deduction", {
    discount_amount: _vm.wasiat.promo_code.amount_discount
  })) + ")")]) : _c('strong', [_vm._v("RM" + _vm._s(_vm.wasiat.price))])])])]), _c('b-button', {
    staticClass: "btn btn-danger",
    attrs: {
      "block": ""
    },
    on: {
      "click": _vm.paymentGateWay
    }
  }, [_vm._v(_vm._s(_vm.$t("agree")))])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }